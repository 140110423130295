import { saasModernTheme } from 'common/theme/saasModern';
import styled from 'styled-components';

export const Description = styled.div`
  font-family: Raleway;
  font-size: 20px;
  font-weight: 500;
  color: white;
  @media (max-width: 450px) {
    font-size: 16px;
  }
`;
