import React, { useEffect, useState } from 'react';
import {
  SectionInfo,
  ContainerCard,
  ContainerInfo,
  Aside,
  Article,
  Video,
} from './info.style';
import SvgAnimation from 'common/components/Card/SVGAnimate';
import Container from 'common/components/Container';
import TypographyTitle from 'common/components/Typography/Title';
import TypographyDescription from 'common/components/Typography/Description';
import BtnGetStarted from 'common/components/Button/BTNGetStarted';
import { PrismicRichText } from 'common/components/PrismicRichText/PrismicRichText';

const InfoSection = ({ slice }) => {
  const [labelSelected, setLabelSelected] = useState('');

  useEffect(() => {
    setLabelSelected(slice.items[0].label);
  }, []);

  const onClickCard = (title) => {
    setLabelSelected(title);
  };

  return (
    <SectionInfo>
      <Container>
        <ContainerCard>
          {slice.items.map((item, index) => (
            <SvgAnimation
              title={item.label}
              key={index}
              defaultIcon={item.default_icon}
              HoverIcon={item.hover_icon}
              onClickCard={onClickCard}
              active={item.label === labelSelected}
            />
          ))}
        </ContainerCard>
      </Container>
      {slice.items.map((item, index) => {
        if (item.label !== labelSelected) {
          return null;
        }
        return (
          <Container key={index}>
            <ContainerInfo>
              <Aside data-aos='zoom-in'>
                <Video
                  playsInline={true}
                  autoPlay={true}
                  muted={true}
                  loop={true}
                  preload='metadata'
                >
                  <source src={item.source_video} type='video/mp4' />
                  Your browser does not support the video tag.
                </Video>
              </Aside>
              <Article>
                <Container data-aos='zoom-in'>
                  <TypographyTitle color='#f6ae2d' as='h3'>
                    {item.title}
                  </TypographyTitle>
                  <TypographyDescription>
                    <PrismicRichText field={item.description} />
                  </TypographyDescription>
                  <BtnGetStarted label={slice.primary.ctatext} />
                </Container>
              </Article>
            </ContainerInfo>
          </Container>
        );
      })}
    </SectionInfo>
  );
};

export default InfoSection;
