import React from 'react';
import { Title } from './title.style';

const TypographyTitle = ({ color, fontWeight, children, size, as = 'h2' }) => {
  return (
    <Title color={color} fontWeight={fontWeight} size={size} as={as}>
      {children}
    </Title>
  );
};

export default TypographyTitle;
