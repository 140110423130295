import React, { useState } from 'react';
import { SvgContainer, GradientBorder, Typography } from './style';
import NextImage from 'common/components/NextImage';

const SVGAnimate = ({ title, defaultIcon, HoverIcon, onClickCard, active }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClickCard = () => {
    onClickCard(title);
  };

  return (
    <GradientBorder
      onMouseMove={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClickCard}
      data-aos='zoom-in'
      active={active}
    >
      <SvgContainer>
        {isHovered ? (
          <NextImage field={HoverIcon} object='fit' />
        ) : (
          <NextImage field={defaultIcon} object='fit' />
        )}
      </SvgContainer>
      <Typography>{title}</Typography>
    </GradientBorder>
  );
};

export default SVGAnimate;
