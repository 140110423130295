import React from 'react';
import { LinkRedirection, LinkWrapper } from './style';
import { translation } from '../../../../../translation/translation';

const BtnGetStarted = ({ label, locale }) => {
  const displayMessage = () => {
    if (label) {
      return label;
    }

    if (locale && !label) {
      return translation.getStared[locale];
    }

    return 'Get started';
  };
  
  return (
    <LinkWrapper>
      <LinkRedirection href='https://www.app.swiftask.ai/'>
        {displayMessage()}
        <svg
          viewBox='-0.5 0 25 25'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          width='30'
          height='30'
          color='currentColor'
        >
          <path
            d='M12 22.4199C17.5228 22.4199 22 17.9428 22 12.4199C22 6.89707 17.5228 2.41992 12 2.41992C6.47715 2.41992 2 6.89707 2 12.4199C2 17.9428 6.47715 22.4199 12 22.4199Z'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M10.5596 8.41992L13.6196 11.29C13.778 11.4326 13.9047 11.6068 13.9914 11.8015C14.0781 11.9962 14.123 12.2068 14.123 12.4199C14.123 12.633 14.0781 12.8439 13.9914 13.0386C13.9047 13.2332 13.778 13.4075 13.6196 13.55L10.5596 16.4199'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </LinkRedirection>
    </LinkWrapper>
  );
};

export default BtnGetStarted;
